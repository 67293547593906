import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { InputSelect, InputDatePicker, InputText, InputCheckbox } from '../../components/Main'
import {
  InteractorAccountAddressSearch,
  InteractorQuotationRequestCreate,
} from '../../interactors/Main'
import { useSelector } from 'react-redux'
import { currencyFormat, findByArray, formatDate, numberFormat } from '../../utility/Helpers'
import { showAlert, confirmationAlert } from '../../utility/SweetAlert'
import { addressMaxLength } from '../../constant/global/validation'
import InputCreatableSelect from '../../components/input/InputCreatableSelect'

const QuotationForm = (data) => {
  const maximum_transaction = 50000000000
  const { t } = useTranslation()
  const date = new Date()
  const { account } = useSelector((state) => state.account)
  const { addressSearch } = InteractorAccountAddressSearch()
  const { requestCreate } = InteractorQuotationRequestCreate()
  const [masterAddress, setMasterAddress] = useState([])
  const [loadingProcess, setLoadingProcess] = useState(false)
  const [listAddress, setListAddress] = useState([{ value: 'NEW', label: t('button.address_add') }])
  const [payloadQuotation, setPayloadQuotation] = useState({
    generate_source: 'CUSTOMER',
    purchase_request_number: '',
    store_uid: null,
    store_name: null,
    customer_account_uid: account.uid,
    customer_company_uid: null,
    request_payment_type: 'TOP',
    request_payment_term_date: null,
    request_payment_installment: 1,
    request_note: '',
    delivery_type: null,
    delivery_cost: 0,
    delivery_cost_currency: 'IDR',
    delivery_insurance: 'N',
    delivery_insurance_cost: 0,
    delivery_insurance_currency: 'IDR',
    delivery_estimated_time: null,
    destination_country_uid: null,
    destination_province_uid: null,
    destination_city_uid: null,
    destination_district_uid: null,
    destination_subdistrict_uid: null,
    destination_postal_code_uid: null,
    destination_address: null,
    consignee_full_name: null,
    consignee_phone_number: null,
    payment_amount: 0,
    payment_amount_currency: 'IDR',
    quotation_items: [],
    created_by: account.full_name,
    agreement: false,
    quotation_installments: [],
  })
  const payloadSearch = useMemo(() => {
    return {
      page: 1,
      limit: 1000,
      keywords: {
        text_search: '',
        account_uid: account.uid,
      },
    }
  }, [account.uid])

  const handleResponseAddress = useCallback(
    (data) => {
      const dataAddress = data.items
      let address = [{ value: 'NEW', label: t('button.address_add') }]
      if (dataAddress && dataAddress.length > 0) {
        setMasterAddress(dataAddress)
        dataAddress.forEach((res) => {
          if (res.address_type === 'SHIP_TO') {
            const obj = { value: res.uid, label: res.consignee_full_name }
            address.push(obj)
          }
        })
      }
      setListAddress(address)
    },
    [t],
  )
  useEffect(() => {
    if (payloadQuotation.quotation_items.length === 0) {
      const dataSelected = data.data
      // PRODUCT
      let products = []
      dataSelected.forEach((item) => {
        const obj = {
          product_title: item.product_title,
          product_thumbnail: item.product_thumbnail,
          product_uid: item.product_uid,
          product_unit_type: item.product_unit_type,
          request_quantity: item.request_quantity,
          request_price: item.request_price,
          request_price_currency: item.request_price_currency,
          origin_country_uid: item.origin_country_uid,
          origin_province_uid: item.origin_province_uid,
          origin_city_uid: item.origin_city_uid,
          origin_district_uid: item.origin_district_uid,
          origin_subdistrict_uid: item.origin_subdistrict_uid,
          origin_postal_code_uid: item.origin_postal_code_uid,
          origin_address: item.origin_address_detail,
        }
        products.push(obj)
      })
      let payload = { ...payloadQuotation }
      payload.quotation_items = products
      payload.store_uid = dataSelected[0].store_uid
      payload.store_name = dataSelected[0].store_name
      setPayloadQuotation(payload)
      addressSearch(
        { ...payloadSearch, keywords: { ...payloadSearch.keywords, address_type: 'SHIP_TO' } },
        handleResponseAddress,
        setLoadingProcess,
      )
    }
  }, [
    account.uid,
    addressSearch,
    data.data,
    handleResponseAddress,
    payloadQuotation,
    payloadSearch,
  ])
  const onValidateInput = (value, name) => {
    setError((state) => ({
      ...state,
      [name]: { ...state[name], errorMsg: value },
    }))
  }
  const [error, setError] = useState({
    delivery: {
      isRequired: true,
      errorMsg: '',
      validateFunc: onValidateInput,
    },
    payment: {
      isRequired: true,
      errorMsg: '',
      validateFunc: onValidateInput,
    },
    request_payment_installment: {
      isRequired: true,
      errorMsg: '',
      validateFunc: onValidateInput,
    },
    address: {
      isRequired: true,
      errorMsg: '',
      validateFunc: onValidateInput,
    },
    agreement: {
      isRequired: true,
      errorMsg: '',
      validateFunc: onValidateInput,
    },
  })
  const handleFormValue = useCallback(
    (value, name) => {
      let payload = { ...payloadQuotation }
      if (name === 'delivery') {
        payload.delivery_type = value
        payload.destination_country_uid = null
        payload.destination_province_uid = null
        payload.destination_city_uid = null
        payload.destination_district_uid = null
        payload.destination_subdistrict_uid = null
        payload.destination_postal_code_uid = null
        payload.destination_address = null
        setPayloadQuotation(payload)
        payload.consignee_full_name = account.full_name
        payload.consignee_phone_number = account.phone_number
        // if (value === 'PICKUP') {
        // }
      }
      if (name === 'payment') {
        payload.request_payment_type = value
        if (value === 'FULL_PAYMENT') {
          payload.request_payment_term_date = ''
        }
      }
      if (name === 'address') {
        if (value !== 'NEW' || !value) {
          const rowAddress = findByArray(masterAddress, 'uid', value)
          payload.destination_country_uid = rowAddress ? rowAddress.country_uid : null
          payload.destination_province_uid = rowAddress ? rowAddress.province_uid : null
          payload.destination_city_uid = rowAddress ? rowAddress.city_uid : null
          payload.destination_district_uid = rowAddress ? rowAddress.district_uid : null
          payload.destination_subdistrict_uid = rowAddress ? rowAddress.subdistrict_uid : null
          payload.destination_postal_code_uid = rowAddress ? rowAddress.postal_code_uid : null
          payload.destination_address = rowAddress ? rowAddress.address_detail : null
          payload.consignee_full_name = rowAddress ? rowAddress.consignee_full_name : null
          payload.consignee_phone_number = rowAddress ? rowAddress.consignee_phone_number : null
        } else {
          payload.destination_address = null
          data.handleModal(data.data, 'ADDRESS')
        }
      }
      if (name === 'request_payment_installment') {
        payload.request_payment_installment = value
      }
      if (name === 'agreement') {
        payload.agreement = value
      }
      setPayloadQuotation(payload)
    },
    [account.full_name, account.phone_number, data, masterAddress, payloadQuotation],
  )
  const handleAgreement = useCallback(
    (e) => {
      const { checked, name } = e.currentTarget
      let selected = false
      if (checked) {
        selected = checked
      }
      handleFormValue(selected, name)

      let msg = null
      if (!selected) {
        msg = t('alert.required')
      }
      error.agreement.validateFunc(msg, name)
    },
    [t, error.agreement, handleFormValue],
  )
  const handleNote = (e) => {
    const { value } = e.target

    if (value.length < addressMaxLength) {
      let payload = { ...payloadQuotation }
      payload.request_note = value
      setPayloadQuotation(payload)
    }
  }

  const handlePurchaseRequestNumber = (arrayData) => {
    // const { value } = e.target
    // if (value.length < titleMaxLength) {
    let payload = { ...payloadQuotation }
    payload.purchase_request_number = [...arrayData]
    setPayloadQuotation(payload)
    // }
  }

  const handlePaymentTermDate = (val) => {
    let payload = { ...payloadQuotation }
    payload.request_payment_term_date = formatDate(val, 'YYYY-MM-DD 23:59:59')
    setPayloadQuotation(payload)
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (
      payloadQuotation.quotation_items === 0 ||
      !payloadQuotation.request_payment_type ||
      !payloadQuotation.delivery_type ||
      (payloadQuotation.delivery_type === 'SHIP_BY_SELLER' &&
        !payloadQuotation.destination_address) ||
      (payloadQuotation.request_payment_type === 'TOP' &&
        !payloadQuotation.request_payment_term_date) ||
      (payloadQuotation.delivery_type === 'SHIP_BY_SELLER' &&
        payloadQuotation.destination_address === '-') ||
      (payloadQuotation.request_payment_type === 'TOP' &&
        (payloadQuotation.request_payment_installment === 0 ||
          !payloadQuotation.request_payment_installment)) ||
      (!payloadQuotation.destination_address &&
        !payloadQuotation.destination_city_uid &&
        !payloadQuotation.destination_province_uid &&
        !payloadQuotation.destination_country_uid &&
        !payloadQuotation.destination_district_uid &&
        !payloadQuotation.destination_subdistrict_uid &&
        !payloadQuotation.destination_postal_code_uid)
    ) {
      showAlert({
        icon: 'warning',
        message: t('alert.required'),
        timer: 0,
      })
      return
    }

    if (!payloadQuotation.agreement) {
      showAlert({
        icon: 'warning',
        message: t('alert.term_condition_rfq_required'),
        timer: 0,
      })
      return
    }

    let subtotal_item = 0
    payloadQuotation?.quotation_items.forEach((sub) => {
      subtotal_item = subtotal_item + sub.request_price * sub.request_quantity
    })
    if (subtotal_item > maximum_transaction) {
      showAlert({
        icon: 'warning',
        message: t('alert.transaction_over_limit'),
        timer: 0,
      })
      return
    }
    if (payloadQuotation.request_payment_type !== 'TOP') {
      payloadQuotation.request_payment_installment = 0
    } else {
      if (payloadQuotation.request_payment_installment > 0) {
        let installments = []
        for (let i = 0; i < payloadQuotation.request_payment_installment; i++) {
          installments.push({
            due_date: payloadQuotation.request_payment_term_date,
            amount: payloadQuotation.payment_amount,
            note: '',
          })
        }
        payloadQuotation.quotation_installments = installments
      }
    }
    if (payloadQuotation.purchase_request_number.length > 0) {
      payloadQuotation.purchase_request_number = payloadQuotation.purchase_request_number
        .map((item) => item.value)
        .join(', ')
    }
    const confirm = await confirmationAlert({
      title: t('title.are_you_sure'),
      message: t('message.confirmation_rfq'),
      icon: 'warning',
    })
    if (confirm) {
      setLoadingProcess(true)
      requestCreate(payloadQuotation)
    }
  }

  // const getPaymentMethod = () => {
  //   let payment_method = [{ value: 'FULL_PAYMENT', label: t(`initial_status.FULL_PAYMENT`) }]
  //   if (account?.allow_term_payment === 'Y') {
  //     payment_method.push({ value: 'TOP', label: t(`initial_status.TOP`) })
  //   }
  //   return payment_method
  // }

  // const getListInstallment = () => {
  //   const array_installment = []
  //   for (let index = 1; index <= 5; index++) {
  //     array_installment.push({ value: index, label: index })
  //   }
  //   return array_installment
  // }

  return (
    <div className="container-fluid mx-0">
      <div
        className="col-12 px-1 font-size-18 text-center mb-1 font-weight-bold"
        id="popup-cart-title"
      >
        <h4>{t('title.request_order_title')}</h4>
      </div>
      <div className="col-12">
        <hr className="mt-0" />
        <div className="row justify-content-between flex-column flex-md-row flex-sm-column mb-3">
          <div className="font-weight-bold">{t('title.seller_name')}</div>
          <div>{payloadQuotation.store_name}</div>
        </div>
        <div className="row justify-content-between flex-column flex-md-row flex-sm-column mb-3">
          <div className="font-weight-bold">{t('invoice.customer_name')}</div>
          <div>{account.full_name}</div>
        </div>
        <div className="row justify-content-between flex-column flex-md-row flex-sm-column mb-3">
          <div className="font-weight-bold">{t('title.purchase_request_number')}</div>
          <div className="col-4 pl-3 pr-0">
            <InputCreatableSelect
              onChange={handlePurchaseRequestNumber}
              value={payloadQuotation.purchase_request_number}
              name="purchase_request_number"
              placeholder={t('placeholder.purchase_request_number')}
            />
            {/* <InputText
              onChange={handlePurchaseRequestNumber}
              value={payloadQuotation.purchase_request_number}
              name="purchase_request_number"
              textarea={true}
              rows={2}
              placeholder={t('placeholder.purchase_request_number')}
            /> */}
          </div>
        </div>
        <div className="row justify-content-between mb-3">
          <div className="font-weight-bold">
            {t('title.delivery_type')}
            <span className="text-danger ml-1">*</span>
          </div>
          <div>
            <InputSelect
              items={[
                { value: 'PICKUP', label: t(`initial_status.PICKUP`) },
                { value: 'SHIP_BY_SELLER', label: t(`initial_status.SHIP_BY_SELLER`) },
              ]}
              name="delivery"
              title={t('title.delivery_type')}
              onChange={handleFormValue}
              {...error.delivery}
            />
          </div>
        </div>
        {/* <div className="row justify-content-between mb-3">
          <div className="font-weight-bold">
            {t('invoice.payment_method')} <span className="text-danger ml-1">*</span>
          </div>
          <div>
            <InputSelect
              items={getPaymentMethod()}
              name="payment"
              title={t('invoice.payment_method')}
              onChange={handleFormValue}
              {...error.payment}
            />
          </div>
        </div> */}
        <div className="row justify-content-between mb-3">
          <div className="font-weight-bold">
            {t('invoice.payment_date')}
            <span className="text-danger ml-1">*</span>
          </div>
          <div className="">
            <InputDatePicker
              onCallback={handlePaymentTermDate}
              initial="DDMMYY"
              required={true}
              minDate={date.setDate(date.getDate() + 1)}
              maxDate={date.setDate(date.getDate() + 60)}
              radius="border-radius-8"
            />
          </div>
        </div>
        {/* {payloadQuotation.request_payment_type === 'TOP' && (
          <>
            <div className="row justify-content-between mb-3">
              <div className="font-weight-bold">
                {t('invoice.payment_date')}
                <span className="text-danger ml-1">*</span>
              </div>
              <div className="">
                <InputDatePicker
                  onCallback={handlePaymentTermDate}
                  initial="DDMMYY"
                  required={true}
                  minDate={date.setDate(date.getDate() + 1)}
                  maxDate={date.setDate(date.getDate() + 60)}
                  radius="border-radius-8"
                />
              </div>
            </div>
            <div className="row justify-content-between mb-3">
              <div className="font-weight-bold">
                {t('invoice.total_installment')}
                <span className="text-danger ml-1">*</span>
              </div>
              <div className="">
                <InputSelect
                  items={getListInstallment()}
                  name="request_payment_installment"
                  title={t('invoice.request_payment_installment')}
                  onChange={handleFormValue}
                  {...error.request_payment_installment}
                />
              </div>
            </div>
          </>
        )} */}
        {payloadQuotation.delivery_type && (
          <div className="row justify-content-between mb-3">
            <div className="font-weight-bold">
              {payloadQuotation.delivery_type === 'SHIP_BY_SELLER'
                ? t('invoice.order_destination')
                : t('invoice.pickup_address')}
              <span className="text-danger ml-1">*</span>
            </div>
            <div>
              <InputSelect
                items={listAddress}
                onChange={handleFormValue}
                name="address"
                title={
                  payloadQuotation.delivery_type === 'SHIP_BY_SELLER'
                    ? t('invoice.order_destination')
                    : t('invoice.pickup_address')
                }
                {...error.address}
              />
            </div>
          </div>
        )}
        {payloadQuotation.destination_address !== null &&
          payloadQuotation.destination_address !== '-' && (
            <div className="d-flex justify-content-between mb-3 border border-radius-25 bg-gray-4 p-3">
              <div className="font-size-14">
                <span className="font-weight-bold">{t(`title.ship_consignee`)}: </span>
                {payloadQuotation.consignee_full_name +
                  ' / ' +
                  payloadQuotation.consignee_phone_number}
                <br />
                <span className="font-weight-bold"> {t(`title.address_detail`)}:</span> <br />
                {payloadQuotation.destination_address}
              </div>
            </div>
          )}
      </div>
      <div className="col-12 table-responsive">
        <hr className="m-0" />
        <table className="container-fluid px-0 overflow-auto">
          <thead>
            <tr>
              <th className="col-5">{t('title.product.title')}</th>
              <th className="col-2">{t('title.request_amount')}</th>
              <th className="col-3">{t('title.price_offer')}</th>
              <th className="col-3">{t('title.total_price_offer')}</th>
            </tr>
          </thead>
          {payloadQuotation?.quotation_items.map((item, key) => {
            return (
              <tbody key={key}>
                <tr>
                  <td className="d-flex flex-column flex-md-row py-2">
                    <div className="col-8 col-md-3 min-width-60 px-0">
                      <img src={item.product_thumbnail} className="img-fluid" alt="" />
                    </div>
                    <div className="col-12 col-md-9 px-0 px-md-3">
                      <span>
                        <strong>{item.product_title}</strong>
                      </span>
                    </div>
                  </td>
                  <td className="col-2">
                    <span>{numberFormat(item.request_quantity)}</span>
                  </td>
                  <td className="col-3">
                    <span>
                      {currencyFormat(item.request_price, item.request_price_currency)} /{' '}
                      <small>{item.product_unit_type}</small>
                    </span>
                  </td>
                  <td className="col-3">
                    {currencyFormat(
                      item.request_price * item.request_quantity,
                      item.request_price_currency,
                    )}
                  </td>
                </tr>
              </tbody>
            )
          })}
        </table>
      </div>
      <div className="col-12">
        <hr className="mt-3" />
        <div className="row justify-content-start align-items-center mb-2 font-size-12">
          <label className="align-items-md-left font-size-14 font-weight-bold form-label">
            {t('title.note')}
          </label>
          <InputText
            onChange={handleNote}
            value={payloadQuotation.request_note}
            name="note"
            textarea={true}
            rows={2}
            placeholder={t('placeholder.note')}
          />
        </div>
        <div className="row justify-content-start align-items-center mb-3 font-weight-400 font-size-9">
          <div className="col-12 col-md-1 px-0">
            <InputCheckbox onChange={handleAgreement} />
          </div>
          <div className="col-12 col-md-11 border overflow-auto nav-scroll bg-gray-7 height-150 p-2">
            <p>
              <span className="font-size-14 font-weight-bold">
                <strong>
                  <u>Ketentuan Permintaan Penawaran</u>
                </strong>
              </span>
            </p>
            <p>
              <span className="font-size-12">
                Dengan menggunakan layanan Permintaan Penawaran (&ldquo;RFQ&rdquo;), Anda setuju
                bahwa Anda telah membaca, memahami, dan setuju untuk terikat dengan Ketentuan
                Penggunaan layanan Permintaan Penawaran Adaloka. Berikut merupakan
                ketentuan-ketentuan sehubungan dengan pengajuan Anda atas setiap Permintaan
                Penawaran (&ldquo;RFQ&rdquo;):
              </span>
            </p>
            <ul>
              <li>
                <span className="font-size-12">
                  Pembeli memiliki waktu 3 x 24 jam untuk memproses penawaran harga (Quotation) dari
                  Penjual agar dapat dilanjutkan ke tahapan selanjutnya. Jika Quotation dari Penjual
                  tidak diproses dalam waktu yang ditentukan, maka pemesanan barang akan dibatalkan
                  secara otomatis.
                </span>
              </li>
              <li>
                <span className="font-size-12">
                  Atas Request for Quotation yang diberikan Pembeli, Penjual diwajibkan memberikan
                  konfirmasi atas RFQ tersebut dengan memberikan penawaran harganya (Quotation)
                  kepada Pembeli dalam waktu 3 x 24 jam setelah RFQ diterima oleh Penjual. Jika
                  Request of Quotation dari Pembeli tidak di proses oleh Penjual dalam waktu yang
                  ditentukan, maka pemesanan barang akan dibatalkan secara otomatis.
                </span>
              </li>
              <li>
                <span className="font-size-12">
                  Permintaan Pembelian Anda harus spesifik dan jelas. Anda tidak diperkenankan
                  memposting permintaan atau informasi apa pun yang tidak relevan dengan layanan
                  Permintaan Penawaran.
                </span>
              </li>
              <li>
                <span className="font-size-12">
                  Anda tidak boleh memposting materi apa pun yang tidak benar, tidak akurat,
                  melanggar hukum, tidak valid, atau melanggar Kebijakan Daftar Produk Adaloka.
                </span>
              </li>
              <li>
                <span className="font-size-12">
                  Untuk menghindari kesalahpahaman dan kekeliruan, Anda tidak diperkenankan
                  memposting Permintaan Pembelian yang sama secara berulang.
                </span>
              </li>
              <li>
                <span className="font-size-12">
                  Dalam hal adanya transaksi yang menyimpang dari kebiasaan pola transaksi atau
                  transaksi yang berasal dari penggunaan harta kekayaan yang diduga berasal dari
                  hasil tindak pindana dan/atau pencucian uang, pengguna setuju untuk melepaskan
                  Adaloka (termasuk Induk Perusahaan, Direktur, dan karyawan) dari klaim dan
                  tuntutan atas kerugian. Pengguna juga setuju bahwa tindakan hukum tersebut atau
                  sengketa yang mungkin timbul dari, berhubungan dengan, atau berada dalam cara
                  apapun berhubungan dengan ketentuan ini akan diselesaikan secara eksklusif dalam
                  yurisdiksi pengadilan Republik Indonesia.
                </span>
              </li>
              <li>
                <span className="font-size-12">
                  Email notifikasi pemberitahuan status Permintaan Pembelian dan pembaharuan kuotasi
                  akan dikirimkan oleh sistem kepada Anda sesuai dengan permintaan Anda dalam
                  layanan Permintaan Penawaran. Jika Anda tidak ingin lagi menerima email tersebut,
                  Anda dapat langsung membuka akun Anda dan membatalkan layanan dengan menutup
                  permintaan Anda sebelumnya.
                </span>
              </li>
              <li>
                <span className="font-size-12">
                  Apabila Anda melanggar salah satu syarat yang disebutkan di atas, Adaloka berhak
                  untuk mengambil tindakan penegakan yang diperlukan secara wajar. Contoh tindakan
                  penegakan tersebut termasuk namun tidak terbatas pada menghapus Permintaan
                  Pembelian Anda yang diposting, membatasi hak Anda untuk memposting Permintaan
                  Pembelian, mengeluarkan peringatan kepada Anda, atau mengakhiri perjanjian
                  keanggotaan Anda dengan Adaloka, dan lain-lain.
                </span>
              </li>
              <li>
                <span className="font-size-12">
                  Adaloka berhak untuk mencocokkan Permintaan Penawaran (&ldquo;RFQ&rdquo;) hanya
                  dengan permintaan yang dianggap sesuai dengan ketentuan RFQ. Adaloka juga berhak
                  menolak Permintaan Pembelian yang tidak memenuhi kriteria apa pun yang dianggap
                  sesuai dan/atau diwajibkan oleh Adaloka, atas kebijakannya sendiri. Contoh
                  Permintaan Pembelian tersebut termasuk, namun tidak terbatas pada, Permintaan
                  Pembelian untuk produk yang tidak termasuk dalam kategori produk yang ditentukan
                  pemasok di Adaloka, Permintaan Pembelian yang tidak sesuai dengan produk,
                  Permintaan Pembelian dengan informasi yang hilang, Permintaan Pembelian untuk
                  produk terlarang dan/atau dibatasi, Permintaan Pembelian yang melanggar syarat dan
                  ketentuan apa pun untuk penggunaan layanan RFQ atau aturan situs web lainnya yang
                  diadopsi oleh Adaloka, dan lain-lain.
                </span>
              </li>
              <li>
                <span className="font-size-12">
                  Anda mengetahui dan menyetujui bahwa Adaloka dapat memberikan informasi kontak
                  Anda, termasuk namun tidak terbatas pada nama kontak, alamat email, telepon,
                  ponsel, dan faks Anda, kepada pemasok agar mereka dapat menghubungi Anda untuk
                  peluang bisnis lebih lanjut.
                </span>
              </li>
              <li>
                <span className="font-size-12">
                  Anda mengetahui dan menyetujui bahwa Adaloka dapat menggunakan informasi
                  keanggotaan Anda, termasuk namun tidak terbatas pada profil perusahaan, gambar
                  profil, riwayat transaksi, informasi kontak, dan lain-lain, untuk memasarkan dan
                  mempromosikan Request for Quotation dan Adaloka (URL:
                  &nbsp;http://www.adaloka.com) dan juga untuk mendukung dan mengoperasikan berbagai
                  program dan layanan Permintaan Penawaran yang ditawarkan kepada anggota dari waktu
                  ke waktu.
                </span>
              </li>
              <li>
                <span className="font-size-12">
                  Anda mengetahui dan menyetujui bahwa Adaloka dapat menggunakan daftar produk Anda
                  dan informasi terkait, termasuk namun tidak terbatas pada gambar produk, daftar
                  produk, spesifikasi produk, dan lain-lain, untuk mendukung dan mengoperasikan
                  berbagai program, layanan, inisiatif pemasaran, dan layanan lain yang ditawarkan
                  oleh Permintaan Penawaran kepada anggota dari waktu ke waktu.
                </span>
              </li>
              <li>
                <span className="font-size-12">
                  Adaloka berhak untuk mengubah dan/atau memodifikasi aturan ini dengan
                  pemberitahuan. Jika terjadi perselisihan, keputusan Adaloka bersifat final dan
                  mengikat.
                </span>
              </li>
              <li>
                <span className="font-size-12">
                  Anda setuju bahwa persyaratan ini diatur, ditafsirkan, dan ditegakkan oleh hukum
                  Indonesia, tanpa memperhatikan pertentangan prinsip hukumnya. Setiap dan semua
                  tindakan hukum, klaim atau proses hukum yang timbul dari, atau sehubungan dengan
                  ini harus dibawa ke pengadilan dengan yurisdiksi yang kompeten di Indonesia.
                </span>
              </li>
              <li>
                <span className="font-size-12">
                  Segala hal yang belum dan/atau tidak diatur dalam syarat dan ketentuan ini maka
                  akan sepenuhnya merujuk pada Syarat dan Ketentuan Layanan Adaloka secara umum.
                </span>
              </li>
            </ul>
            <p>&nbsp;</p>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mb-3">
          {!loadingProcess ? (
            <button
              type="submit"
              className="btn btn-md btn-primary-dark font-weight-700"
              to="#"
              onClick={handleSubmit}
            >
              {t('button.send_request')}
            </button>
          ) : (
            <button type="submit" className="btn btn-md btn-primary font-weight-700" to="#">
              {t('title.loading')}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default QuotationForm
