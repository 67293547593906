import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CSVLink } from 'react-csv'
import InputTextButton from '../../input/InputTextButton'

const WideCardFilter = (data) => {
  const { filter, payload } = data

  const { t } = useTranslation()
  const [payloadFilter, setPayloadFilter] = useState(payload)
  const handleTextSearch = (e) => {
    setPayloadFilter({
      ...payloadFilter,
      keywords: {
        ...payloadFilter.keywords,
        text_search: e.target.value,
      },
    })
  }

  const handleClearSearch = () => {
    let objPayload = { ...payloadFilter }
    objPayload.page = 1
    objPayload.keywords.text_search = ''
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }
  const handleSearch = (e) => {
    e.preventDefault()
    let objPayload = { ...payloadFilter }
    objPayload.page = 1
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }
  const handleStatus = (e) => {
    e.preventDefault()
    let objPayload = { ...payloadFilter }
    objPayload.page = 1
    objPayload.keywords.status = e.target.value
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }
  const handlePaymentStatus = (e) => {
    e.preventDefault()
    let objPayload = { ...payloadFilter }
    objPayload.page = 1
    objPayload.keywords.payment_status = e.target.value
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }
  const handlePaymentType = (e) => {
    e.preventDefault()
    let objPayload = { ...payloadFilter }
    objPayload.page = 1
    objPayload.keywords.payment_type = e.target.value
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }
  const handleAddressType = (e) => {
    e.preventDefault()
    let objPayload = { ...payloadFilter }
    objPayload.page = 1
    objPayload.keywords.address_type = e.target.value
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }
  return (
    <>
      {/* FILTER */}
      <div className={filter ? 'row mb-3 flex-column flex-md-row' : 'd-none'}>
        {/* <div className="col-12 col-md-8 col-lg-10 mb-2 mb-md-0 d-flex flex-column flex-md-row mt-2"> */}
        {filter?.text_search && (
          <InputTextButton
            value={payloadFilter.keywords.text_search}
            placeholder={data.label_input}
            onChange={handleTextSearch}
            onSubmit={handleSearch}
            onClear={handleClearSearch}
          />
        )}
        {filter?.payment_type && (
          <div className="row col-12 col-md-3 mr-2 mt-2 mt-lg-0">
            <select
              className="form-control height-40 py-2 px-3"
              name="status"
              required
              value={payloadFilter.keywords.payment_type}
              onChange={handlePaymentType}
            >
              {filter.payment_type.map((row, index) => {
                return (
                  <option key={index} value={row.value}>
                    {row.label}
                  </option>
                )
              })}
            </select>
          </div>
        )}
        {filter?.status && (
          <div className="row col-12 col-md-3 mr-2 mt-2 mt-lg-0">
            <select
              className="form-control height-40 py-2 px-3"
              name="status"
              required
              value={payloadFilter.keywords.status}
              onChange={handleStatus}
            >
              {filter.status.map((row, index) => {
                return (
                  <option key={index} value={row.value}>
                    {row.label}
                  </option>
                )
              })}
            </select>
          </div>
        )}
        {filter?.address_type && (
          <div className="row col-12 col-md-3 mr-2 mt-2 mt-lg-2">
            <select
              className="form-control height-40 py-2 px-3"
              name="address_type"
              required
              value={payloadFilter.keywords.address_type}
              onChange={handleAddressType}
            >
              {filter.address_type.map((row, index) => {
                return (
                  <option key={index} value={row.value}>
                    {row.label}
                  </option>
                )
              })}
            </select>
          </div>
        )}
        {filter?.payment_status && (
          <div className="row col-12 col-md-3 mr-2 mt-2 mt-lg-2">
            <select
              className="form-control height-40 py-2 px-3"
              name="payment_status"
              required
              value={payloadFilter.keywords.payment_status}
              onChange={handlePaymentStatus}
            >
              {filter.payment_status.map((row, index) => {
                return (
                  <option key={index} value={row.value}>
                    {row.label}
                  </option>
                )
              })}
            </select>
          </div>
        )}
        {filter?.download && (
          <div className="col col-md-4 col-lg-2 d-flex justify-content-start justify-content-md-end">
            {filter.download?.data && (
              <CSVLink
                data={filter.download.data}
                headers={filter.download.headers}
                filename={filter.download.filename}
                separator={';'}
                className="d-inline btn btn-primary btn-xs m-2 font-size-10"
              >
                <i className="fa fa-download"></i> {t('button.download_csv')}
              </CSVLink>
            )}
          </div>
        )}
      </div>
      {/* </div> */}
    </>
  )
}

export default WideCardFilter
