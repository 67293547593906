import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loading, WideCardFilter, Pagination } from '../Main'
import { currencyFormat, formatDate } from '../../utility/Helpers'
import { InteractorDownloadPdf } from '../../interactors/Main'
const WideCardFee = (data) => {
  const { loading, datacard, payload, download } = data
  const { t } = useTranslation()
  const { getFee } = InteractorDownloadPdf()

  const [payloadFilter, setPayloadFilter] = useState(payload)

  const filter = {
    text_search: true,
    status: [
      { value: '', label: t(`title.select_status`) },
      { value: 'ISSUED', label: t(`fee.status.ISSUED`) },
      { value: 'PAID', label: t(`fee.status.PAID`) },
      { value: 'UNPAID', label: t(`fee.status.UNPAID`) },
    ],
    download: download,
  }

  const handleFilter = (row) => {
    let objPayload = { ...payloadFilter }
    objPayload.page = 1
    objPayload.keywords.text_search = row.keywords.text_search
    objPayload.keywords.paid_status = row.keywords.status
    delete objPayload.keywords.status
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }

  const handlePageChange = (page) => {
    let objPayload = { ...payloadFilter }
    objPayload.page = page
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }

  const handleDownloadInvoice = (row) => {
    getFee(row)
  }

  // CARD AREA
  const CardHeader = (data) => {
    const row = data.data
    return (
      <>
        <div className="row">
          <div className="col-8 mt-2">
            <span className="d-inline-block p-2">
              <small>{t('fee.fee_code')}</small> <br />
              <strong>{row.fee_code}</strong>
            </span>
          </div>
          <div className="col-4 mt-1 d-flex justify-content-end">
            <span className="d-inline-block py-4">
              <small>{t('fee.fee_status')}</small> <br />
              <span className={`text-gray font-weight-bold text-gray`}>
                {t(`fee.status.${row.paid_status}`)}
              </span>
            </span>
          </div>
        </div>
        <hr className="m-0" />
      </>
    )
  }

  const CardBody = (data) => {
    const row = data.data
    return (
      <>
        <div className="row">
          <div className="col-xs-12 col-md-9">
            <table className="table table-borderless m-2">
              <tbody>
                <tr>
                  <td className="col-xs-6 p-1">{t('fee.fee_title')}</td>
                  <td className="p-1">
                    <div className="font-weight-bold">{row?.fee_title}</div>
                  </td>
                </tr>
                <tr>
                  <td className="col-xs-6 p-1">{t('fee.fee_description')}</td>
                  <td className="p-1">
                    <div className="font-weight-bold">{row?.fee_description}</div>
                  </td>
                </tr>
                <tr>
                  <td className="col-xs-6 p-1">{t('fee.created_date')}</td>
                  <td className="p-1">
                    <div className="font-weight-bold">
                      {formatDate(row?.created_date, 'DD/MM/YYYY HH:mm')}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="col-xs-6 p-1">{t('fee.bill_to_address')}</td>
                  <td className="p-1">
                    <div className="font-weight-bold">{row?.bill_to_address}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-12 col-md-3 d-flex text-center justify-content-center justify-content-md-end mb-2">
            <div className="mt-2 mr-0 mr-md-3">
              <span className="font-size-16 font-weight-bold text-grey">
                {t('fee.total_amount')}
              </span>
              <br />
              <span className="font-size-20 font-weight-bold text-dark">
                {currencyFormat(row?.bill_amount, 'IDR')}
              </span>
            </div>
          </div>
        </div>
      </>
    )
  }
  const CardFooter = (data) => {
    const row = data.data
    return (
      <>
        <hr className="m-0" />
        <div className="row">
          <div className="col-12 mt-2 mb-2 d-flex justify-content-end">
            <div className="lightbox d-flex align-items-center">
              <div
                onClick={() => handleDownloadInvoice(row)}
                className="d-inline btn btn-primary btn-xs m-2 font-size-10 pointer"
              >
                <i className="fa fa-download"></i> {t('button.download_fee')}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {/* FILTER */}
      <WideCardFilter
        filter={filter}
        payload={payload}
        handleFilter={handleFilter}
        label_input={t('placeholder.payment_history_filter')}
      />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Loading />
        </div>
      ) : (
        <>
          {datacard.items?.length > 0 ? (
            <>
              {datacard.items.map((row, key) => {
                return (
                  <div key={key}>
                    <div className="border border-width-2 border-color-3 borders-radius-6 container wide-card rounded mb-5">
                      <CardHeader data={row} />
                      <div className="row">
                        <div className="col-12">
                          <CardBody data={row} />
                        </div>
                      </div>
                      <CardFooter data={row} />
                    </div>
                  </div>
                )
              })}
              <Pagination data={datacard.pages} onChangePage={handlePageChange} />
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">{t('message.data_not_found')}</div>
            </>
          )}
        </>
      )}
    </>
  )
}

export default WideCardFee
