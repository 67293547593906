import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { currencyFormat, formatDate } from '../../utility/Helpers'

const InteractorCsvFee = () => {
  const { t } = useTranslation()
  const csvFee = useCallback(
    (data, callback) => {
      const headers = [
        { label: 'No', key: 'no' },
        { label: t('fee.fee_code'), key: 'fee_code' },
        { label: t('fee.fee_title'), key: 'fee_title' },
        { label: t('fee.fee_description'), key: 'fee_description' },
        { label: t('fee.created_date'), key: 'created_date' },
        { label: t('fee.bill_to_address'), key: 'bill_to_address' },
        { label: t('fee.total_amount'), key: 'total_amount' },
        { label: t('fee.paid_status'), key: 'paid_status' },
      ]

      // MAPING ROW
      let results = []
      data?.forEach((row, idx) => {
        const obj = {
          no: idx + 1,
          fee_code: row.fee_code,
          fee_title: row.fee_title,
          fee_description: row.fee_description,
          created_date: formatDate(row.created_date, 'DD/MM/YYYY HH:mm'),
          bill_to_address: row.bill_to_address,
          total_amount: currencyFormat(row.bill_amount, 'IDR'),
          paid_status: t(`fee.status.${row.paid_status}`),
        }
        results.push(obj)
      })
      callback({
        headers: headers,
        data: results,
        filename: 'FEE-' + new Date().getTime() + '.csv',
      })
    },
    [t],
  )
  return { csvFee }
}

export default InteractorCsvFee
