import React from 'react'
import {
  Home,
  PageNotFound,
  ProductIndex,
  ProductDetail,
  StoreDetail,
  Cart,
  BlogIndex,
  BlogDetail,
  ProfileIndex,
  Register,
  SignIn,
  PasswordForgot,
  PasswordChange,
  PasswordConfirmation,
  OtpVerification,
  QuotationRequest,
  Order,
  PaymentHistory,
  PaymentStatus,
  Notification,
  PageHelp,
  PageAbout,
  PageFaq,
  PageOrderGuide,
  PagePaymentGuide,
  PagePrivacy,
  PageSellerGuide,
  PageSellerRules,
  PageToc,
  Chat,
  AddressForm,
  AddressIndex,
  OrderView,
  Bill,
  Fee,
} from './../containers/Main'

import i18n from './../utility/i18n'
function getTitle(key) {
  let lang = i18n.t(key)
  if (lang === undefined) {
    lang = 'Retail Ecommerce, Built For You'
  }
  return lang
}
const RoutePath = [
  {
    path: '/',
    title: getTitle('menu.home'),
    element: <Home />,
    auth: true,
  },
  {
    path: '/product',
    title: getTitle('menu.product_list'),
    element: <ProductIndex />,
    auth: true,
  },
  {
    path: '/product/category/:slug/:uid',
    title: getTitle('menu.product_list'),
    element: <ProductIndex />,
    auth: true,
  },
  {
    path: '/product/detail/:slug/:id',
    title: getTitle('menu.product_detail'),
    element: <ProductDetail />,
    auth: true,
  },
  {
    path: '/cart',
    title: getTitle('menu.cart'),
    element: <Cart />,
    auth: true,
  },
  {
    path: '/store/:slug/:id',
    title: getTitle('menu.store_detail'),
    element: <StoreDetail />,
    auth: true,
  },
  {
    path: '/blog',
    title: getTitle('menu.blog_list'),
    element: <BlogIndex />,
    auth: true,
  },
  {
    path: '/blog/detail/:slug/:id',
    title: getTitle('menu.blog_detail'),
    element: <BlogDetail />,
    auth: true,
  },
  {
    path: '/account/profile',
    title: getTitle('menu.my_profile'),
    element: <ProfileIndex />,
    auth: true,
  },
  {
    path: '/account/address',
    title: getTitle('menu.my_address'),
    element: <AddressIndex />,
    auth: true,
  },
  {
    path: '/account/address/add',
    title: getTitle('menu.my_address_add'),
    element: <AddressForm />,
    auth: true,
  },
  {
    path: '/account/address/edit/:slug/:id',
    title: getTitle('menu.my_address_edit'),
    element: <AddressForm initial="edit" />,
    auth: true,
  },
  {
    path: '/account/register',
    title: getTitle('menu.register_account'),
    element: <Register />,
    auth: false,
  },
  {
    path: '/account/signin',
    title: getTitle('menu.signin'),
    element: <SignIn />,
    auth: false,
  },
  {
    path: '/account/forgot-password',
    title: getTitle('menu.forgot_password'),
    element: <PasswordForgot />,
    auth: false,
  },
  {
    path: '/account/confirmation-password',
    title: getTitle('menu.confirmation_password'),
    element: <PasswordConfirmation />,
    auth: false,
  },
  {
    path: '/account/change-password',
    title: getTitle('menu.change_password'),
    element: <PasswordChange />,
    auth: true,
  },
  {
    path: '/account/security-verification/:token',
    title: getTitle('menu.otp_verification'),
    element: <OtpVerification />,
    auth: false,
  },
  {
    path: '/quotation/request',
    title: getTitle('menu.quotation_request'),
    element: <QuotationRequest />,
    auth: true,
  },
  {
    path: '/order',
    title: getTitle('menu.order'),
    element: <Order />,
    auth: true,
  },

  {
    path: '/order/view/:id',
    title: getTitle('menu.order_view'),
    element: <OrderView />,
    auth: true,
  },
  {
    path: '/bill',
    title: getTitle('menu.bill'),
    element: <Bill />,
    auth: true,
  },
  {
    path: '/fee',
    title: getTitle('menu.fee'),
    element: <Fee />,
    auth: true,
  },
  {
    path: '/payment',
    title: getTitle('menu.payment_history'),
    element: <PaymentHistory />,
    auth: true,
  },
  {
    path: '/payment/status/:type',
    title: getTitle('menu.payment_status'),
    element: <PaymentStatus />,
    auth: true,
  },
  {
    path: '/notification',
    title: getTitle('menu.notification'),
    element: <Notification />,
    auth: true,
  },
  {
    path: '/page/help',
    title: getTitle('menu.help'),
    element: <PageHelp />,
    auth: false,
  },
  {
    path: '/page/how-to-order',
    title: getTitle('menu.how_to_order'),
    element: <PageOrderGuide />,
    auth: true,
  },
  {
    path: '/page/how-to-pay',
    title: getTitle('menu.how_to_pay'),
    element: <PagePaymentGuide />,
    auth: true,
  },
  {
    path: '/page/how-do-start-selling',
    title: getTitle('menu.how_to_be_seller'),
    element: <PageSellerGuide />,
    auth: true,
  },
  {
    path: '/page/seller-rules',
    title: getTitle('menu.seller_rules'),
    element: <PageSellerRules />,
    auth: true,
  },
  {
    path: '/page/about-us',
    title: getTitle('menu.about_us'),
    element: <PageAbout />,
    auth: true,
  },
  {
    path: '/page/faq',
    title: getTitle('menu.faq'),
    element: <PageFaq />,
    auth: true,
  },
  {
    path: '/page/terms-and-conditions',
    title: getTitle('menu.toc'),
    element: <PageToc />,
    auth: true,
  },
  {
    path: '/page/privacy-policy',
    title: getTitle('menu.privacy'),
    element: <PagePrivacy />,
    auth: true,
  },
  {
    path: '/chat',
    title: getTitle('menu.chat'),
    element: <Chat />,
    auth: true,
  },
  { path: '*', title: '404', element: <PageNotFound />, auth: false },
]

export default RoutePath
