import { useCallback } from 'react'
import ApiService from '../../services/ApiService'
import { getItem } from '../../utility/LocalStorage'

const InteractorDownloadPdf = () => {
  const lng = getItem('language') ? getItem('language').toUpperCase() : 'ID'

  const getRFQ = useCallback(
    (payload) => {
      payload.language = lng
      const filename = 'RFQ-' + payload.request_code + '.pdf'
      ApiService.fileRequest('/download-pdf/rfq', payload, filename, 'application/pdf')
    },
    [lng],
  )

  const getTransaction = useCallback(
    (payload) => {
      payload.language = lng
      const filename = 'ORDER-' + payload.transaction_code + '.pdf'
      ApiService.fileRequest('/download-pdf/transaction', payload, filename, 'application/pdf')
    },
    [lng],
  )

  const getInvoice = useCallback(
    (payload) => {
      payload.language = lng
      const filename = 'INVOICE-' + payload.invoice_code + '.pdf'
      ApiService.fileRequest('/download-pdf/invoice', payload, filename, 'application/pdf')
    },
    [lng],
  )

  const getFee = useCallback(
    (payload) => {
      payload.language = lng
      const filename = 'FEE-' + payload.fee_code + '.pdf'
      ApiService.fileRequest('/download-pdf/fee-invoice', payload, filename, 'application/pdf')
    },
    [lng],
  )

  return { getRFQ, getTransaction, getInvoice, getFee }
}

export default InteractorDownloadPdf
