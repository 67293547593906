import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'
import { showAlert } from '../../../utility/SweetAlert'
import { useTranslation } from 'react-i18next'

const InteractorEscrowWithdrawal = () => {
  const { t } = useTranslation()

  const escrowWithdrawal = useCallback(
    (payload) => {
      ApiService.jsonRequest('/fund-escrow/allowed-withdrawal', payload, (response) => {
        if (response.status_code === 200) {
          showAlert({
            icon: 'success',
            message: t('response_message.send_success'),
            timer: 2000,
            redirect: '/bill',
          })
        } else {
          showAlert({
            icon: 'warning',
            message: t('response_message.send_failed'),
            timer: 2000,
            redirect: '/bill',
          })
        }
      })
    },
    [t],
  )
  return { escrowWithdrawal }
}

export default InteractorEscrowWithdrawal
