import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { currencyFormat, formatDate } from '../../utility/Helpers'

const InteractorCsvBill = () => {
  const { t } = useTranslation()
  const csvBill = useCallback(
    (data, callback) => {
      const headers = [
        { label: 'No', key: 'no' },
        { label: t('title.bill.invoice_code'), key: 'invoice_code' },
        { label: t('title.bill.bill_title'), key: 'bill_title' },
        { label: t('title.bill.status'), key: 'status' },
        { label: t('title.bill.due_date'), key: 'due_date' },
        { label: t('title.bill.total_amount'), key: 'total_amount' },
      ]

      // MAPING ROW
      let results = []
      data?.forEach((row, idx) => {
        const obj = {
          no: idx + 1,
          invoice_code: row.invoice_code,
          status: t(`status.bill.${row.status}`),
          bill_title: row.bill_title,
          due_date: formatDate(row.due_date, 'DD/MM/YYYY HH:mm'),
          total_amount: currencyFormat(row.total_amount, 'IDR'),
        }
        results.push(obj)
      })
      callback({
        headers: headers,
        data: results,
        filename: 'BILL-' + new Date().getTime() + '.csv',
      })
    },
    [t],
  )
  return { csvBill }
}

export default InteractorCsvBill
