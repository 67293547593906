import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { InteractorAccountAddressForm } from '../../../interactors/Main'
import { InputText } from '../../../components/Main'
import { numberRegex } from '../../../constant/global/validation'

const AddressForm = ({ initial, datas, onCallback }) => {
  const { t } = useTranslation()
  const {
    errorSubmit,
    payloadLocation,
    optionProvince,
    optionCity,
    optionDistrict,
    optionSubdistrict,
    optionPostalCode,
    handlerInput,
    handlerProvince,
    handlerAddressType,
    handlerCity,
    handlerDistrict,
    handlerSubdistrict,
    handlerPostalcode,
    onSubmit,
    error,
  } = InteractorAccountAddressForm(initial, datas)
  const {
    title,
    consignee_full_name,
    consignee_phone_number,
    province_uid,
    city_uid,
    district_uid,
    subdistrict_uid,
    postal_code_uid,
    address,
    address_type,
  } = payloadLocation

  const address_type_select = [
    { value: '', label: t(`title.select_address_type`) },
    { value: 'SHIP_TO', label: t(`initial_status.SHIP_TO`) },
    { value: 'BILL_TO', label: t(`initial_status.BILL_TO`) },
  ]

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(onCallback)
  }

  return (
    <>
      <h3 className="d-md-flex justify-content-md-center align-items-md-center font-size-26 font-weight-bold mb-4">
        {initial === 'edit' ? t('title.address_edit') : t('title.address_add')}
      </h3>
      <form onSubmit={handleSubmit}>
        <div className="form-margin">
          <div className="row">
            <div className="container">
              <div className="col-md-12 mx-auto">
                <div className="form-group row">
                  <div className="col-sm-6 mb-3">
                    <InputText
                      label={t('form.address_title')}
                      value={title}
                      onChange={handlerInput}
                      error={!!error.title}
                      errorMsg={error.title}
                      name="title"
                    />
                  </div>
                  <div className="col-sm-6 mb-3">
                    <InputText
                      label={t('form.consignee_full_name')}
                      value={consignee_full_name}
                      onChange={handlerInput}
                      error={!!error.consignee_full_name}
                      errorMsg={error.consignee_full_name}
                      name="consignee_full_name"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-6 mb-3">
                    <InputText
                      label={t('form.consignee_phone_number')}
                      value={consignee_phone_number}
                      onChange={handlerInput}
                      error={!!error.consignee_phone_number}
                      errorMsg={error.consignee_phone_number}
                      name="consignee_phone_number"
                      validator={numberRegex}
                    />
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label className="align-items-md-left font-size-12 font-weight-bold">
                      {t('form.address_type')}
                    </label>
                    <select
                      className={`form-control ${
                        error.address_type ? 'border border-danger text-danger' : ''
                      }`}
                      name="address_type"
                      value={address_type}
                      onChange={handlerAddressType}
                    >
                      {address_type_select?.map((item, index) => {
                        return (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        )
                      })}
                    </select>
                    <span
                      className={
                        error.address_type
                          ? ' ml-3 lead text-danger font-size-13 visible'
                          : 'd-none invisible'
                      }
                    >
                      {error.address_type ? error.address_type : ''}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-6 mb-3">
                    <label className="align-items-md-left font-size-12 font-weight-bold">
                      {t('form.province')}
                    </label>
                    <select
                      className={`form-control ${
                        error.province_uid ? 'border border-danger text-danger' : ''
                      }`}
                      name="province_uid"
                      value={province_uid}
                      onChange={handlerProvince}
                    >
                      <option value="">{`${t('field_input.select')} ${t('form.province')}`}</option>
                      {optionProvince?.map((item, index) => {
                        return (
                          <option key={index} value={item.uid}>
                            {item.province_name}
                          </option>
                        )
                      })}
                    </select>
                    <span
                      className={
                        error.province_uid
                          ? ' ml-3 lead text-danger font-size-13 visible'
                          : 'd-none invisible'
                      }
                    >
                      {error.province_uid ? error.province_uid : ''}
                    </span>
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label className="align-items-md-left font-size-12 font-weight-bold">
                      {t('form.city')}
                    </label>
                    <select
                      className={`form-control ${
                        error.city_uid ? 'border border-danger text-danger' : ''
                      }`}
                      name="city_uid"
                      value={city_uid}
                      onChange={handlerCity}
                    >
                      <option value="">{`${t('field_input.select')} ${t('form.city')}`}</option>
                      {optionCity?.map((item, index) => {
                        return (
                          <option key={index} value={item.uid}>
                            {item.city_name}
                          </option>
                        )
                      })}
                    </select>
                    <span
                      className={
                        error.city_uid
                          ? ' ml-3 lead text-danger font-size-13 visible'
                          : 'd-none invisible'
                      }
                    >
                      {error.city_uid ? error.city_uid : ''}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-6 mb-3">
                    <label className="align-items-md-left font-size-12 font-weight-bold">
                      {t('form.district')}
                    </label>
                    <select
                      className={`form-control ${
                        error.district_uid ? 'border border-danger text-danger' : ''
                      }`}
                      name="district_uid"
                      value={district_uid}
                      onChange={handlerDistrict}
                    >
                      <option value="">{`${t('field_input.select')} ${t('form.district')}`}</option>
                      {optionDistrict?.map((item, index) => {
                        return (
                          <option key={index} value={item.uid}>
                            {item.district_name}
                          </option>
                        )
                      })}
                    </select>
                    <span
                      className={
                        error.district_uid
                          ? ' ml-3 lead text-danger font-size-13 visible'
                          : 'd-none invisible'
                      }
                    >
                      {error.district_uid ? error.district_uid : ''}
                    </span>
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label className="align-items-md-left font-size-12 font-weight-bold">
                      {t('form.sub_district')}
                    </label>
                    <select
                      className={`form-control ${
                        error.subdistrict_uid ? 'border border-danger text-danger' : ''
                      }`}
                      name="subdistrict_uid"
                      value={subdistrict_uid}
                      onChange={handlerSubdistrict}
                    >
                      <option value="">{`${t('field_input.select')} ${t(
                        'form.sub_district',
                      )}`}</option>
                      {optionSubdistrict?.map((item, index) => {
                        return (
                          <option key={index} value={item.uid}>
                            {item.subdistrict_name}
                          </option>
                        )
                      })}
                    </select>
                    <span
                      className={
                        error.subdistrict_uid
                          ? ' ml-3 lead text-danger font-size-13 visible'
                          : 'd-none invisible'
                      }
                    >
                      {error.subdistrict_uid ? error.subdistrict_uid : ''}
                    </span>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-6 mb-3">
                    <label className="align-items-md-left font-size-12 font-weight-bold">
                      {t('form.postal_code')}
                    </label>
                    <select
                      className={`form-control ${
                        error.postal_code_uid ? 'border border-danger text-danger' : ''
                      }`}
                      name="postal_code_uid"
                      value={postal_code_uid}
                      onChange={handlerPostalcode}
                    >
                      <option value="">{`${t('field_input.select')} ${t(
                        'form.postal_code',
                      )}`}</option>
                      {optionPostalCode?.map((item, index) => {
                        return (
                          <option key={index} value={item.uid}>
                            {item.postal_code}
                          </option>
                        )
                      })}
                    </select>
                    <span
                      className={
                        error.postal_code_uid
                          ? ' ml-3 lead text-danger font-size-13 visible'
                          : 'd-none invisible'
                      }
                    >
                      {error.postal_code_uid ? error.postal_code_uid : ''}
                    </span>
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label className="align-items-md-left font-size-12 font-weight-bold form-label">
                      {t('form.address')}
                    </label>
                    <InputText
                      value={address}
                      onChange={handlerInput}
                      error={!!error.address}
                      errorMsg={error.address}
                      name="address"
                      textarea={true}
                      rows={6}
                      placeholder={t('field_input.address_title')}
                    />
                  </div>
                </div>
                <div className="form-group row"></div>
                <p
                  className={
                    errorSubmit === true
                      ? 'text-center lead custom-error-field visible'
                      : 'd-none invisible'
                  }
                >
                  {t('response_message.error_submit_address')}
                </p>
                <button type="submit" className="btn btn-primary-dark-w px-5 float-right">
                  {t('button.save')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

AddressForm.propTypes = {
  initial: PropTypes.string,
  datas: PropTypes.any,
  datacard: PropTypes.any,
  onCallback: PropTypes.any,
}

export default AddressForm
