import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorFeeSearch = () => {
  const feeSearch = useCallback((payload, callback, loading) => {
    ApiService.jsonRequest('/fund-fee/search', payload, (response) => {
      if (response.status_code === 200) {
        if (response.data) {
          callback(response.data)
        }
      }
      loading(false)
    })
  }, [])
  return { feeSearch }
}

export default InteractorFeeSearch
