import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loading, WideCardFilter, Pagination, ImageModal } from '../Main'
import { Link } from 'react-router-dom'
import { currencyFormat, formatDate } from '../../utility/Helpers'
import {
  InteractorPaymentCreate,
  InteractorEscrowWithdrawal,
  InteractorDownloadPdf,
} from '../../interactors/Main'
import { useSelector } from 'react-redux'
import { confirmationAlert } from '../../utility/SweetAlert'
import { FindFileType } from '../../utility/Helpers'
import pdfLogo from '../../assets/image/icons/pdfLogo.png'

const WideCardBill = (data) => {
  const { loading, datacard, payload, download } = data
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const { paymentCreate } = InteractorPaymentCreate()
  const { escrowWithdrawal } = InteractorEscrowWithdrawal()
  const { getInvoice } = InteractorDownloadPdf()

  const [payloadFilter, setPayloadFilter] = useState(payload)
  const [loadingRequest, setLoadingRequest] = useState(false)
  const filter = {
    text_search: true,
    status: [
      { value: '', label: t(`title.select_status`) },
      { value: 'ISSUED', label: t(`status.bill.ISSUED`) },
      { value: 'PENDING_APPROVAL', label: t(`status.bill.PENDING_APPROVAL`) },
      { value: 'PAID', label: t(`status.bill.PAID`) },
      { value: 'CANCELLED', label: t(`status.bill.CANCELLED`) },
      { value: 'REFUND_PROCESS', label: t(`status.bill.REFUND_PROCESS`) },
      { value: 'REFUND_DONE', label: t(`status.bill.REFUND_DONE`) },
    ],
    download: download,
  }

  const handleFilter = (row) => {
    let objPayload = { ...payloadFilter }
    objPayload.page = 1
    objPayload.keywords.text_search = row.keywords.text_search
    objPayload.keywords.status = row.keywords.status
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }

  const handlePageChange = (page) => {
    let objPayload = { ...payloadFilter }
    objPayload.page = page
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }

  const handlePayment = (row) => {
    const payload_payment = {
      reference_category: 'BILL',
      reference_uid: row.uid,
      account_uid: account.uid,
      company_uid: null,
      payment_title: row.bill_title,
      payment_method: 'PAYMENT_GATEWAY',
      payment_amount: row.total_amount,
      payment_amount_currency: 'IDR',
      payment_attachment_media_type: null,
      payment_attachment: null,
      payment_gateway_company: 'XENDIT',
      description: null,
      invoice_duration: 86400,
      invoice_success_url: process.env.REACT_APP_DOMAIN_MARKETPLACE + '/payment/status/success',
      invoice_failure_url: process.env.REACT_APP_DOMAIN_MARKETPLACE + '/payment/status/failure',
      invoice_customer: {
        given_names: account.full_name,
        email: account.email_address,
        mobile_number: account.phone_number ?? '02180623840',
      },
      created_by: account.full_name,
    }
    setLoadingRequest(true)
    paymentCreate(payload_payment)
  }

  const handleWithdrawal = async (row) => {
    const payloadEscrow = {
      bill_uid: row.uid,
      created_by: account.full_name,
    }
    const ok = await confirmationAlert({
      title: t('title.are_you_sure'),
      message: t('message.confirmation_withdrawal'),
      icon: 'warning',
    })
    if (ok) {
      setLoadingRequest(true)
      escrowWithdrawal(payloadEscrow)
    }
  }

  const handleDownloadInvoice = (row) => {
    getInvoice(row)
  }

  // CARD AREA
  const CardHeader = (data) => {
    const row = data.data
    return (
      <>
        <div className="row">
          <div className="col-8 mt-2">
            <span className="d-inline-block p-2">
              <small>{t('title.bill.invoice_code')}</small> <br />
              <strong>{row.invoice_code}</strong>
            </span>
          </div>
          <div className="col-4 mt-1 d-flex justify-content-end">
            <span className="d-inline-block py-4">
              <small>{t('title.bill.status')}: </small>
              <span className={`text-gray font-weight-bold text-gray`}>
                {t(`status.bill.${row.status}`)}
              </span>
            </span>
          </div>
        </div>
        <hr className="m-0" />
      </>
    )
  }
  const CardBody = (data) => {
    const row = data.data
    return (
      <>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <table className="table table-borderless m-2">
              <tbody>
                <tr>
                  <td className="col-xs-6 p-1">{t('title.bill.bill_title')}</td>
                  <td className="p-1">
                    <div className="font-weight-bold">{row.bill_title}</div>
                  </td>
                </tr>
                <tr>
                  <td className="col-xs-6 p-1">{t('title.bill.due_date')}</td>
                  <td className="p-1">
                    <div className="font-weight-bold">
                      {formatDate(row.due_date, 'DD/MM/YYYY HH:mm')}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="col-xs-6 p-1">{t('title.bill.payment_method')}</td>
                  <td className="p-1">
                    <div className="font-weight-bold">
                      {t(`title.payment_method.${row.payment_method.toLowerCase()}`)}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-12 col-md-6 d-flex text-center justify-content-center justify-content-md-end mb-2">
            <div className="mt-2 mr-0 mr-md-3">
              <span className="font-size-16 font-weight-bold text-grey">
                {t('title.bill.total_amount')}
              </span>
              <br />
              <span className="font-size-20 font-weight-bold text-dark">
                {currencyFormat(row.total_amount, 'IDR')}
              </span>
            </div>
          </div>
        </div>
      </>
    )
  }
  const CardFooter = (data) => {
    const row = data.data
    return (
      <>
        <hr className="m-0" />
        <div className="row">
          <div className="col-12 mt-2 mb-2 d-flex justify-content-end">
            {row.status === 'ISSUED' && row?.allowed_payment === 'Y' && (
              <Link
                to={'#'}
                onClick={() => {
                  if (!row.payment_invoice_url) {
                    handlePayment(row)
                  } else {
                    window.location = row.payment_invoice_url
                  }
                }}
                className="d-inline btn btn-primary btn-xs m-3 font-size-10"
              >
                <i className="fa fa-credit-card"></i> {t('button.payment_process')}
              </Link>
            )}
            {['PROCESSED', 'SHIPPED', 'DELIVERED'].includes(row.status_transaction) &&
              row.status === 'DONE' &&
              row.allowed_withdrawal === 'Y' && (
                <Link
                  to={'#'}
                  onClick={() => {
                    handleWithdrawal(row)
                  }}
                  className="d-inline btn btn-green btn-xs m-2 font-size-10"
                >
                  <i className="fa fa-check"></i> {t('button.allowed_withdrawal')}
                </Link>
              )}
            <div className="lightbox d-flex align-items-center">
              {['PAYMENT_GATEWAY', 'CREDIT_CARD'].includes(row.payment_method) ? (
                // METHOD VIRTUAL ACCOUNT / CREDIT CARD
                <>
                  {['ISSUED', 'PROCESSED', 'PAID'].includes(row.status) ? (
                    <div
                      onClick={() => handleDownloadInvoice(row)}
                      className="d-inline btn btn-primary btn-xs m-2 font-size-10 pointer"
                    >
                      <i className="fa fa-download"></i> {t('button.download_invoice')}
                    </div>
                  ) : (
                    <small className="text-grey">{`${t('title.bill.bill_file')} ${t(
                      'title.no_data',
                    )}`}</small>
                  )}
                </>
              ) : (
                // METHOD MANUAL TRANSFER
                <>
                  {row.file_invoice ? (
                    ['jpg', 'jpeg', 'png'].includes(
                      FindFileType(row.file_invoice.toLowerCase()),
                    ) ? (
                      <ImageModal title={t('title.bill.bill_file')} src={row.file_invoice} />
                    ) : (
                      <a href={row.file_invoice} target={'_blank'} rel="noreferrer" download>
                        <img
                          className="form-picture-image"
                          src={pdfLogo}
                          width="60"
                          height="60"
                          alt=""
                        />
                      </a>
                    )
                  ) : (
                    <small className="text-grey">{`${t('title.bill.bill_file')} ${t(
                      'title.no_data',
                    )}`}</small>
                  )}
                </>
              )}
            </div>
            <Link to={'/page/help'} className="d-inline btn btn-red btn-xs m-3 font-size-10">
              <i className="fa fa-question-circle"></i> {t('button.help')}
            </Link>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {/* FILTER */}
      <WideCardFilter
        filter={filter}
        payload={payload}
        handleFilter={handleFilter}
        label_input={t('placeholder.payment_history_filter')}
      />
      {loading || loadingRequest ? (
        <div className="d-flex justify-content-center">
          <Loading />
        </div>
      ) : (
        <>
          {datacard.items?.length > 0 ? (
            <>
              {datacard.items.map((row, key) => {
                return (
                  <div key={key}>
                    <div className="border border-width-2 border-color-3 borders-radius-6 container wide-card rounded mb-5">
                      <CardHeader data={row} />
                      <div className="row">
                        <div className="col-12">
                          <CardBody data={row} />
                        </div>
                      </div>
                      <CardFooter data={row} />
                    </div>
                  </div>
                )
              })}
              <Pagination data={datacard.pages} onChangePage={handlePageChange} />
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">{t('message.data_not_found')}</div>
            </>
          )}
        </>
      )}
    </>
  )
}

export default WideCardBill
