import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Loading, WideCardFilter, Pagination } from '../Main'
import {
  InteractorAccountAddressDelete,
  InteractorAccountAddressUpdate,
} from '../../interactors/Main'
import { confirmationAlert } from './../../utility/SweetAlert'
import { Link } from 'react-router-dom'

const WideCardAddress = (data) => {
  const { loading, datacard, payload, download } = data
  const { t } = useTranslation()
  const [payloadFilter, setPayloadFilter] = useState(payload)
  const { addressDelete } = InteractorAccountAddressDelete()
  const { addressUpdate } = InteractorAccountAddressUpdate()

  const columns = useMemo(
    () => [
      {
        title: t('title.consignee_full_name'),
        column: 'consignee_full_name',
        type: 'text',
        className: 'w-25 text-nowrap-sm',
      },
      {
        title: t('title.address_type'),
        column: 'address_type',
        type: 'text',
        className: 'w-25 text-nowrap-sm',
      },
      {
        title: t('title.consignee_phone_number'),
        column: 'consignee_phone_number',
        type: 'text',
        className: 'w-25 text-nowrap-sm',
      },
      {
        title: t('title.address_detail'),
        column: 'address_detail',
        type: 'text',
        className: 'w-50 text-nowrap-sm',
      },
    ],
    [t],
  )
  const filter = {
    text_search: true,
    address_type: [
      { value: '', label: t(`title.select_address_type`) },
      { value: 'SHIP_TO', label: t(`initial_status.SHIP_TO`) },
      { value: 'BILL_TO', label: t(`initial_status.BILL_TO`) },
    ],
    status: false,
    download: download,
  }
  const handleFilter = (row) => {
    let objPayload = { ...payloadFilter }
    objPayload.page = 1
    objPayload.keywords.text_search = row.keywords.text_search
    objPayload.keywords.status = row.keywords.status
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }
  const handlePageChange = (page) => {
    let objPayload = { ...payloadFilter }
    objPayload.page = page
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }

  // CARD AREA
  const CardHeader = (obj) => {
    const row = obj.data
    return (
      <>
        <div className="row">
          <div className="col-8 mt-2">
            <span className="d-inline-block p-2">
              {t('title.address_title')} <br />
              <strong>{row.title}</strong>
            </span>
          </div>
          {row.address_primary === 'Y' && (
            <div className="col-4 mt-2 d-flex justify-content-end">
              <span className="d-inline-block py-4">
                {t('title.status')}:
                <span className="text-gray font-weight-bold">
                  {row.address_primary === 'Y' ? ` ${t('title.address_primary')}` : ''}
                </span>
              </span>
            </div>
          )}
        </div>
        <hr className="m-0" />
      </>
    )
  }
  const CardBody = (obj) => {
    return (
      <>
        <div className="table-responsive-sm">
          <table className="table table-borderless">
            <thead>
              <tr>
                {columns.map((row, index) => {
                  return (
                    <th key={index} className={row.className}>
                      {row.title}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {Object.entries(obj).map(([key, item]) => (
                <tr key={key}>
                  {columns.map((row, index) => {
                    return (
                      <td className={row.className} key={index}>
                        {row.column === 'address_type'
                          ? t(`initial_status.${item[row.column]}`)
                          : item[row.column]}
                      </td>
                    )
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    )
  }
  const CardFooter = (obj) => {
    const row = obj.data
    return (
      <>
        <hr className="m-0" />
        <div className="row">
          <div className="col-12 mt-2 mb-2 d-flex justify-content-end">
            {row.address_primary === 'N' && (
              <button
                type="button"
                value={row.uid}
                className="btn btn-xs btn-light mr-1"
                onClick={async () => {
                  const confirm = await confirmationAlert({
                    title: t('title.are_you_sure'),
                    message: t('message.confirmation_address_primary'),
                    icon: 'warning',
                  })
                  if (confirm) {
                    let payload = { ...row }
                    payload.address_primary = 'Y'
                    addressUpdate(payload)
                  }
                }}
              >
                <i className="fa fa-check-circle"></i> {t('button.set_address_primary')}
              </button>
            )}
            <Link
              to="#"
              className="btn btn-xs btn-primary mr-1"
              onClick={() => data.handleEdit(row)}
            >
              <i className="fa fa-edit"></i> {t('button.edit')}
            </Link>
            <button
              type="button"
              value={row.uid}
              className="btn btn-xs btn-dark mr-1"
              onClick={async () => {
                const confirm = await confirmationAlert({
                  title: t('title.are_you_sure'),
                  message: t('message.confirmation_delete'),
                  icon: 'warning',
                })
                if (confirm) {
                  addressDelete({ uid: row.uid })
                }
              }}
            >
              <i className="fa fa-trash"></i> {t('button.delete')}
            </button>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {/* FILTER */}
      <WideCardFilter
        filter={filter}
        payload={payload}
        handleFilter={handleFilter}
        label_input={t('placeholder.address_filter')}
      />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Loading />
        </div>
      ) : (
        <>
          {datacard.items?.length > 0 ? (
            <>
              {datacard?.items.map((row, key) => {
                return (
                  <div key={key}>
                    <div className="border border-width-2 border-color-3 borders-radius-6 container wide-card rounded mb-5">
                      <CardHeader data={row} />
                      <div className="row">
                        <div className="col-12">
                          <CardBody data={row} />
                        </div>
                      </div>
                      <CardFooter data={row} />
                    </div>
                  </div>
                )
              })}
              <Pagination data={datacard.pages} onChangePage={handlePageChange} />
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">{t('message.data_not_found')}</div>
            </>
          )}
        </>
      )}
    </>
  )
}

export default WideCardAddress
