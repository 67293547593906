import React, { useState } from 'react'

import CreatableSelect from 'react-select/creatable'
import { titleMaxLength } from '../../constant/global/validation'
import { t } from 'i18next'
const components = {
  DropdownIndicator: null,
}

const createOption = (label) => ({
  label,
  value: label,
})

const InputCreatableSelect = (data) => {
  const {
    label,
    name,
    // inputClassName,
    onChange,
    // validator,
    error,
    errorMsg,
    placeholder,
    required,
    disable = false,
  } = data
  const [inputValue, setInputValue] = useState('')
  const [value, setValue] = useState([])
  const [exceedMaxString, setExceedMaxString] = useState(false)
  const handleKeyDown = (event) => {
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (inputValue.length < titleMaxLength) {
          setExceedMaxString(false)
          setValue((prev) => {
            onChange([...prev, createOption(inputValue)])
            return [...prev, createOption(inputValue)]
          })
          setInputValue('')
          event.preventDefault()
        } else {
          setExceedMaxString(true)
        }
        break
      default:
    }
  }

  return (
    <>
      {label && (
        <label className="align-items-md-left font-size-12 font-weight-bold form-label">
          {label} {required && <span className="text-danger">*</span>}
        </label>
      )}
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={(newValue) => {
          onChange(newValue)
          setValue(newValue)
        }}
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        value={value}
        disabled={disable}
        name={name}
        placeholder={placeholder}
      />
      {error && (
        <span className={'ml-3 lead text-danger font-size-13 visible'}>
          {errorMsg ? errorMsg : ''}
        </span>
      )}
      {exceedMaxString && (
        <span className={'lead text-danger font-size-13 visible'}>
          {t('response_message.maximum_length_purchase_request_number')}
        </span>
      )}
    </>
  )
}

export default InputCreatableSelect
