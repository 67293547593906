import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Menu from './Menu'

const MenuAccountMobile = ({ setMenuCallback }) => {
  const { t } = useTranslation()
  return (
    <>
      <ul id="headerSidebarList" className="u-header-collapse__nav">
        <li className="">
          <Menu
            to="/"
            className="u-header-collapse__nav-link font-weight-bold"
            callbackSidebar={() => setMenuCallback(false)}
          >
            <i className="fa fa-home text-warning font-size-12 mr-2"></i>
            {t('menu.home')}
          </Menu>
        </li>
        <li>
          <Menu
            to="/account/profile"
            className="u-header-collapse__nav-link font-weight-bold"
            callbackSidebar={() => setMenuCallback(false)}
          >
            <i className="fa fa-user text-warning font-size-12 mr-2"></i>
            {t('menu.my_profile')}
          </Menu>
        </li>
        <li>
          <Menu
            to="/account/address"
            className="u-header-collapse__nav-link font-weight-bold"
            callbackSidebar={() => setMenuCallback(false)}
          >
            <i className="fa fa-address-card text-warning font-size-12 mr-2"></i>
            {t('menu.my_address')}
          </Menu>
        </li>
        <li>
          <Menu
            to="/quotation/request"
            className="u-header-collapse__nav-link font-weight-bold"
            callbackSidebar={() => setMenuCallback(false)}
          >
            <i className="fa fa-file text-warning font-size-12 mr-2"></i>
            {t('menu.quotation_request')}
          </Menu>
        </li>
        <li>
          <Menu
            to="/order"
            className="u-header-collapse__nav-link font-weight-bold"
            callbackSidebar={() => setMenuCallback(false)}
          >
            <i className="fa fa-shopping-cart text-warning font-size-12 mr-2"></i>
            {t('menu.order')}
          </Menu>
        </li>
        <li>
          <Menu
            to="/bill"
            className="u-header-collapse__nav-link font-weight-bold"
            callbackSidebar={() => setMenuCallback(false)}
          >
            <i className="fa fa-money-bill text-warning font-size-12 mr-2"></i>
            {t('menu.bill')}
          </Menu>
        </li>
        <li>
          <Menu
            to="/fee"
            className="u-header-collapse__nav-link font-weight-bold"
            callbackSidebar={() => setMenuCallback(false)}
          >
            <i className="fa fa-money-check text-warning font-size-12 mr-2"></i>
            {t('menu.fee')}
          </Menu>
        </li>
        {/* <li>
          <Menu
            to="/payment"
            className="u-header-collapse__nav-link font-weight-bold"
            callbackSidebar={() => setMenuCallback(false)}
          >
            <i className="fa fa-credit-card text-warning font-size-12 mr-2"></i>
            {t('menu.payment_history')}
          </Menu>
        </li> */}
        <li>
          <Menu
            to="/account/change-password"
            className="u-header-collapse__nav-link font-weight-bold"
            callbackSidebar={() => setMenuCallback(false)}
          >
            <i className="fa fa-key text-warning font-size-12 mr-2"></i>
            {t('menu.change_password')}
          </Menu>
        </li>
        <li>
          <Menu
            to="/account/signout"
            className="u-header-collapse__nav-link font-weight-bold"
            callbackSidebar={() => setMenuCallback(false)}
          >
            <i className="fa fa-power-off text-warning font-size-12 mr-2"></i>
            {t('menu.signout')}
          </Menu>
        </li>
      </ul>
    </>
  )
}

MenuAccountMobile.propTypes = {
  menu: PropTypes.any,
  setMenuCallback: PropTypes.any,
  children: PropTypes.any,
}

export default MenuAccountMobile
